import styled from 'styled-components'

export const Container = styled.div`
  .logo:hover {
    cursor: pointer;
  }

  @media (max-width: 800px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
      margin-top: 38px;
      justify-content: center;
    }
  }

  @media (min-width: 801px) {
    .desktop {
      display: flex;
      margin-top: 69px;
      justify-content: center;
    }
    .mobile {
      display: none;
    }
  }

  .container {
    max-width: 1153px;
    align-self: center;
    display: flex;
    flex: 1;
  }

  .left {
    width: 50%;
    padding-left: 56px;
  }

  .left img {
    width: 100%;
  }

  .right {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    padding-top: 29px;
    padding-left: 65px;
    padding-right: 35px;
  }

  .title {
    padding-top: 25px;
    font-size: 42px;
    font-weight: 900;
    line-height: 68px;
    padding-bottom: 7px;
    text-align: center;
  }

  .redline {
    background: #c62326;
    width: 42px;
    height: 2px;
    margin-bottom: 12px;
  }

  .greyline {
    background: #ebebeb;
    height: 2px;
    width: 100%;
    margin-bottom: 16px;
    padding-left: 44px;
    padding-right: 44px;
  }

  .description {
    font-size: 24px;
    color: #7c7c7c;
    line-height: 34px;
    padding-bottom: 19px;
    text-align: center;
  }

  .sns {
    display: flex;
    align-self: center;
    flex-direction: row;
    direction: rtl;
  }

  .sns a {
    padding: 8px;
  }

  .sns .platforms {
    margin-top: 8px;
    padding-left: 8px;
    color: #424242;
    font-size: 13px;
  }

  .mobile-container {
    height: 100vh;
    max-width: 350px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .mobile-maintaince {
    margin-top: 46px;
    margin-bottom: 9px;
    width: 100%;
  }

  .title-mobile {
    padding-top: 25px;
    font-size: 32px;
    font-weight: 900;
    line-height: 52px;
    padding-bottom: 3px;
    text-align: center;
  }

  .description-mobile {
    font-size: 18px;
    color: #7c7c7c;
    line-height: 25px;
    padding-bottom: 25px;
    text-align: center;
  }

  .greyline-mobile {
    background: #ebebeb;
    height: 2px;
    width: 100%;
    margin-bottom: 25px;
    padding-left: 44px;
    padding-right: 44px;
  }
`
