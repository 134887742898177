import React from 'react'
import { Container } from './styles'

import maintaince from './maintaince.svg'
import desktopLogo from './desktopLogo.svg'
import articleFacebook from './articleFacebook.svg'
import articleTwitter from './articleTwitter.svg'
import articleInstagram from './articleInstagram.svg'
import articleYoutube from './articleYoutube.svg'
import articleLinkedin from './articleLinkedin.svg'
import siteLogo from './siteLogo.svg'

const reload = () => {
  window.location.reload()
}

const Maintenance = () => (
  <Container>
    <div className="desktop">
      <div className="container">
        <div className="left">
          <img src={maintaince} loading="lazy" alt="Asharq" />
        </div>

        <div className="right">
          <img className="logo" onClick={reload} src={desktopLogo} />
          <div className="title">الموقع قيد الصيانة</div>
          <div className="redline"></div>
          <div className="description" dir="rtl">
            عذرا، نقوم حالياً بتحسين وصيانة الموقع وسوف يعود للعمل في أسرع وقت
            ممكن.
          </div>
          <div className="greyline"></div>
          <div className="sns">
            <div className="platforms">منصاتنا</div>

            <a
              rel="noopener noreferrer nofollow"
              target="_blank"
              href="https://www.facebook.com/asharqnews"
            >
              <img
                style={{ width: 20, height: 20 }}
                src={articleFacebook}
                alt="facebook"
                loading="lazy"
              />
            </a>

            <a
              rel="noopener noreferrer nofollow"
              target="_blank"
              href="https://twitter.com/Asharqnews"
            >
              <img
                style={{ width: 20, height: 20 }}
                src={articleTwitter}
                alt="twitter"
                loading="lazy"
              />
            </a>

            <a
              rel="noopener noreferrer nofollow"
              target="_blank"
              href="https://www.instagram.com/asharqnews/"
            >
              <img
                style={{ width: 20, height: 20 }}
                src={articleInstagram}
                alt="instagram"
                loading="lazy"
              />
            </a>

            <a
              rel="noopener noreferrer nofollow"
              target="_blank"
              href="https://www.youtube.com/c/asharqnews"
            >
              <img
                style={{ width: 20, height: 20 }}
                src={articleYoutube}
                alt="youtube"
                loading="lazy"
              />
            </a>

            <a
              rel="noopener noreferrer nofollow"
              target="_blank"
              href="https://www.linkedin.com/company/asharqnews/"
            >
              <img
                style={{ width: 20, height: 20 }}
                src={articleLinkedin}
                alt="linkedin"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="mobile">
      <div className="mobile-container">
        <img
          alt="Asharq"
          className="logo"
          onClick={reload}
          src={desktopLogo}
          loading="lazy"
        />

        <img
          className="mobile-maintaince"
          src="./maintaince.svg"
          loading="lazy"
          alt="Asharq Maintenance"
        />
        <div className="title-mobile">الموقع قيد الصيانة</div>
        <div className="redline"></div>
        <div className="description-mobile" dir="rtl">
          عذرا، نقوم حالياً بتحسين وصيانة الموقع وسوف يعود للعمل في أسرع وقت
          ممكن.
        </div>
        <div className="greyline"></div>
        <div className="sns">
          <div className="platforms">منصاتنا</div>

          <a
            rel="noopener noreferrer nofollow"
            target="_blank"
            href="https://www.facebook.com/asharqnews"
          >
            <img
              style={{ width: 20, height: 20 }}
              src={articleFacebook}
              alt="facebook"
              loading="lazy"
            />
          </a>

          <a
            rel="noopener noreferrer nofollow"
            target="_blank"
            href="https://twitter.com/Asharqnews"
          >
            <img
              style={{ width: 20, height: 20 }}
              src={articleTwitter}
              alt="twitter"
              loading="lazy"
            />
          </a>

          <a
            rel="noopener noreferrer nofollow"
            target="_blank"
            href="https://www.instagram.com/asharqnews/"
          >
            <img
              style={{ width: 20, height: 20 }}
              src={articleInstagram}
              alt="instagram"
              loading="lazy"
            />
          </a>

          <a
            rel="noopener noreferrer nofollow"
            target="_blank"
            href="https://www.youtube.com/c/asharqnews"
          >
            <img
              style={{ width: 20, height: 20 }}
              src={articleYoutube}
              alt="youtube"
              loading="lazy"
            />
          </a>

          <a
            rel="noopener noreferrer nofollow"
            target="_blank"
            href="https://www.linkedin.com/company/asharqnews/"
          >
            <img
              style={{ width: 20, height: 20 }}
              src={articleLinkedin}
              alt="linkedin"
              loading="lazy"
            />
          </a>
        </div>
      </div>
    </div>

    <div style={{ display: 'none' }}>
      <header>
        <img onClick={reload} className="logo" src={siteLogo} loading="lazy" />
      </header>
      <section className="main">
        <h1>Maintaince 2</h1>
        <div>This site is under maintaince.</div>
      </section>
      <footer>
        <div className="footerSns"></div>
      </footer>
    </div>
  </Container>
)

export default Maintenance
